import './App.css';

function App() {
  return (
    <div className="App">
      <h1 className="text-center text-primary">I am Rijwan Hasan Arman</h1>
    </div>
  );
}

export default App;
